import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import moment from "moment";
import axios from 'axios';
import GenAIResultIndex from './GenAIResultIndex';
import { Modal } from "react-bootstrap";
import { imsLoader, crossIcon, GenAiDOCIcon, GenAiPDFIcon, GenAiPPTIcon } from '../../assets/index';
import threadingService from "../../services/threadingService";
import trackingService from "../../services/trackingService";
import { LocationOrigin, MISEHCP, AllContent, getCurrentDate, MISEHCPFilter, responseData, responseMultiData, multiFilesAccept } from '../../utils/utilityServices';
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import { ContextMenu, ContextMenuTrigger, showMenu } from "react-contextmenu";
import ApiServices from "../../utils/apiServices";
import CollectionpopUp from "./../PopUp/CollectionpopUp"
import GenAIDocumentTypesFilters from './GenAIDocumentTypesFilters';
import ToggleSwitch from '../ToggleSwitch/index';
import { clone, uniq } from 'underscore'
import {
    UserData
} from "../../auth/Authorization";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import IdleTimer from 'react-idle-timer';
import Button from "./../Button";
import GenAIClose from "./GenAIClose";
import { processFilters, GenAI2DocTypes } from '../../utils/documentTypeUtilityServices';
import GenAIAnsweringDisclaimer from './GenAIAnsweringDisclaimer'
let ts = new threadingService();
let _locationOrigin = LocationOrigin;
//_locationOrigin = 'https://predev-ims.jnj.com';

const ReactLike = 'Like';
const ReactUnlike = 'Unlike';
const ReactNone = 'None';

class GenAISearchIndex extends Component {
    static contextType = GenAIAnsweringContext;

    constructor(props) {
        super(props);
        this.messagesEnd = createRef();
        this.myDiv = createRef();
        var _timeout = 600;
        this.idleTimer = null;
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            sessionID: props.location.state ? props.location.state.sessionID : (props.match.params && props.match.params.id) ? props.match.params.id : null,
            question: props.location.state ? props.location.state.newQuestion : null,
            attachedFiles: props.location.state ? props.location.state.attachedFiles : [],
            selectedDocumentType: [],
            threadID: props.location.state ? props.location.state.threadID : null,
            selectedFilters: props.location.state ? props.location.state.selectedFilters : [],
            renderfilters: props.location.state ? props.location.state.renderfilters : [],
            isComprehensive: props.location.state ? clone(props.location.state.isComprehensive) : false,
            uploadFilePlaceHolder: "",
            uploadFilePlaceHolderTitle: "",
            isAttachedFile: false,
            newQuestion: "",
            chatConversation: [],
            isProcessing: false,
            chatHistory: [],
            attachedFilePopupCollapsed: false,
            scrollingUp: false,
            isFirstQuestion: true,
            isCollectionProcessing: false,
            threadCollections: [],
            collectionPopup: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            newAttachedFiles: [],
            removedAttachedFileIndex: -1,
            spinnerMessage: '',
            timeout: 1000 * _timeout,
            isTimeOut: false,
            updateAnyFile: (props.location.state && props.location.state.attachedFiles && props.location.state.attachedFiles.length > 0) ? true : false,
            isDTPopupOpen: false,
            isMultiFileThread: (props.location.state ? props.location.state.attachedFiles : []).length > 0,
            showDisclaimerPopup: false,
        }
        this.onIdle = this._onIdle.bind(this)
    }
    componentDidMount = async () => {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        if (this.props.location.state && this.props.location.state.sessionID || (this.props.match.params && this.props.match.params.id)) {
            await this.context.getLatestAnswerThread();
            await this.getAnswerThreadDataBySessionId(this.state.sessionID);
            await this.getAnswerCollectionBySessionId(this.state.sessionID);
            let { sessionID, attachedFiles } = this.state;
            if (attachedFiles.length > 0) {
                this.context.updateSessionId(sessionID);
            } else {
                this.context.updateSessionId("");
            }
        } else {
            this.props.history.push('/genAIAnswering');
        }
        // if (this.props.location.state && this.props.location.state.sessionID && this.props.location.state.newQuestion) {
        //     this.runGenAISearch(this.props.location.state.newQuestion);
        // } else {
        //     this.props.history.push('/genAIAnswering');
        // }

        this.manageFilePlaceHolder();
    }
    handleBeforeUnload = async () => {
        let { sessionID, attachedFiles } = this.state;
        if (attachedFiles.length > 0) {
            sessionStorage.setItem("GenAIAnsweringSessionId", sessionID);
        }
        else {
            sessionStorage.removeItem("GenAIAnsweringSessionId");
        }
    }

    async componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        let { sessionID, attachedFiles } = this.state;
        if (attachedFiles.length > 0) {
            let response = await ts.deleteIndexBySessionId(sessionID);
        }
        await this.context.updateSessionId("");

    }
    async componentWillReceiveProps(nextProps) {
        if ((nextProps.match.params && nextProps.match.params.id && this.state.sessionID !== nextProps.match.params.id)) {
            let { sessionID, attachedFiles } = this.state;
            if (attachedFiles.length > 0) {
                let response = await ts.deleteIndexBySessionId(sessionID);
            }
            this.setState({
                sessionID: nextProps.match.params.id,
                question: "",
                attachedFiles: [],
                selectedDocumentType: [],
                newQuestion: "",
                chatConversation: [],
                chatHistory: [],
                threadCollections: [],
                attachedFilePopupCollapsed: false,
                isFirstQuestion: true,
                updateAnyFile: false,
            }, async () => {
                await this.context.updateSessionId("");
                await this.getAnswerThreadDataBySessionId(this.state.sessionID);
                await this.getAnswerCollectionBySessionId(this.state.sessionID);
            })
        }

        if (nextProps
            && nextProps.location
            && nextProps.location.state
            && nextProps.location.state.sessionID
            && this.state.sessionID != nextProps.location.state.sessionID) {
            let { sessionID, attachedFiles } = this.state;
            if (attachedFiles.length > 0) {
                let response = await ts.deleteIndexBySessionId(sessionID);
            }
            this.setState({
                sessionID: nextProps.location.state.sessionID,
                question: nextProps.location.state.newQuestion,
                attachedFiles: nextProps.location.state.attachedFiles,
                threadID: nextProps.location.state.threadID,
                newQuestion: "",
                chatConversation: [],
                chatHistory: [],
                attachedFilePopupCollapsed: false,
                threadCollections: [],
                isFirstQuestion: true,
                updateAnyFile: (nextProps.location.state && nextProps.location.state.attachedFiles && nextProps.location.state.attachedFiles.length > 0) ? true : false
            }, async () => {
                if (nextProps.location.state.attachedFiles.length > 0) {
                    await this.context.updateSessionId(nextProps.location.state.sessionID);
                } else {
                    this.context.updateSessionId("");
                }
                this.runGenAISearch(nextProps.location.state.newQuestion);
                await this.getAnswerCollectionBySessionId(this.state.sessionID);
            })
        }

    }
    handleCloseCollection = async (isUpdateAnything) => {
        this.setState({ collectionPopup: false });
        if (isUpdateAnything) {
            await this.getAnswerCollectionBySessionId(this.state.sessionID);
        }
    };
    handleAddtoCollectionClick = (e) => {
        this.setState({ collectionPopup: true });
    }
    async getAnswerCollectionBySessionId(sessionID) {
        this.setState({ isCollectionProcessing: true })
        const service = new ApiServices();
        let response = await service.getAnswerCollectionBySessionId([sessionID]);
        if (response && response.length > 0) {
            this.setState({ threadCollections: response })
        } else {
            this.setState({ threadCollections: [] })
        }
        this.setState({ isCollectionProcessing: false })
    }
    async getAnswerThreadDataBySessionId(sessionID) {
        this.setState({ isProcessing: true })
        if (UserData && UserData.sub) {
            let response = await ts.getAnswerThreadDataBySessionId(UserData.sub, sessionID)
            if (response && response.data.length > 0) {
                let _chatConversation = this.state.chatConversation;
                let { chatHistory } = this.state;
                let _question = "", _attachedFiles = [], _threadID = '',
                    _selectedFilters = [], _renderfilters = [], _isMultiFileThread = false, _isComprehensive = false;// _selectedDocumentType = [],
                let threadGuid = uuidv4();
                response.data.map((threadItem, threadIndex) => {
                    let source = threadItem._source;
                    let _chatHistoryThreadID = '';
                    if (threadIndex === 0) {
                        _question = source.answeringinfo.question;
                        // _selectedDocumentType = source.answeringinfo.selectedDocumentType;
                    }
                    if (threadIndex === (response.data.length - 1)) {
                        if (source.answeringinfo.attachedFiles) {
                            _attachedFiles = source.answeringinfo.attachedFiles;
                        }
                        if (source.answeringinfo.thread_id && _attachedFiles.length === 0) {
                            _threadID = source.answeringinfo.thread_id;
                        } else {
                            _threadID = threadGuid;
                        }
                        if (source.answeringinfo.isComprehensive) {
                            _isComprehensive = clone(source.answeringinfo.isComprehensive);
                        }
                        if (source.answeringinfo.selectedFilters) {
                            _selectedFilters = Object.assign([], source.answeringinfo.selectedFilters)
                            if (_selectedFilters.includes("Labels/Labels/CCDS")) {
                                _selectedFilters = _selectedFilters.filter(x => x !== "Labels/Labels/CCDS");
                                _selectedFilters.push("Labels/Labels/CCDS Current Clean In Use");
                            }
                            if (_selectedFilters.includes("Labels/Labels/USPI")) {
                                _selectedFilters = _selectedFilters.filter(x => x !== "Labels/Labels/USPI");
                                _selectedFilters.push("Labels/Labels/USPI Current Clean In Use");
                            }
                            if (_selectedFilters.includes("Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)")) {
                                _selectedFilters = _selectedFilters.filter(x => x !== "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)");
                                _selectedFilters.push("Labels/Labels/CCDS Current Clean In Use");
                                _selectedFilters.push("Labels/Labels/USPI Current Clean In Use");
                                _selectedFilters.push("Labels/Labels");
                            }
                        }
                        if (source.answeringinfo.renderfilters) {
                            _renderfilters = Object.assign([], source.answeringinfo.renderfilters)
                            if (_renderfilters.includes("Labels/Labels/CCDS")) {
                                _renderfilters = _renderfilters.filter(x => x !== "Labels/Labels/CCDS");
                                _renderfilters.push("Labels/Labels/CCDS Current Clean In Use");
                            }
                            if (_renderfilters.includes("Labels/Labels/USPI")) {
                                _renderfilters = _renderfilters.filter(x => x !== "Labels/Labels/USPI");
                                _renderfilters.push("Labels/Labels/USPI Current Clean In Use");
                            }
                            if (_renderfilters.includes("Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)")) {
                                _renderfilters = _renderfilters.filter(x => x !== "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)");
                                _renderfilters.push("Labels/Labels");
                            }
                        }
                        _isMultiFileThread = _attachedFiles.length > 0;
                    }
                    if (source.answeringinfo.thread_id) {
                        _chatHistoryThreadID = source.answeringinfo.thread_id;
                    }
                    if (source.answeringinfo.output_answer) {
                        chatHistory.push({ question: source.answeringinfo.question, answer: source.answeringinfo.output_answer, threadID: _chatHistoryThreadID ? _chatHistoryThreadID : sessionID });
                    } else {
                        chatHistory.push({ question: source.answeringinfo.question, answer: "", threadID: _chatHistoryThreadID ? _chatHistoryThreadID : sessionID });
                    }

                    let _threadSelectedFilters = [], _threadRenderfilters = [];

                    if (source.answeringinfo.selectedFilters) {
                        _threadSelectedFilters = Object.assign([], source.answeringinfo.selectedFilters)
                        if (_threadSelectedFilters.includes("Labels/Labels/CCDS")) {
                            _threadSelectedFilters = _threadSelectedFilters.filter(x => x !== "Labels/Labels/CCDS");
                            _threadSelectedFilters.push("Labels/Labels/CCDS Current Clean In Use");
                        }
                        if (_threadSelectedFilters.includes("Labels/Labels/USPI")) {
                            _threadSelectedFilters = _threadSelectedFilters.filter(x => x !== "Labels/Labels/USPI");
                            _threadSelectedFilters.push("Labels/Labels/USPI Current Clean In Use");
                        }
                        if (_threadSelectedFilters.includes("Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)")) {
                            _threadSelectedFilters = _threadSelectedFilters.filter(x => x !== "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)");
                            _threadSelectedFilters.push("Labels/Labels/CCDS Current Clean In Use");
                            _threadSelectedFilters.push("Labels/Labels/USPI Current Clean In Use");
                            _threadSelectedFilters.push("Labels/Labels");
                        }
                    }
                    if (source.answeringinfo.renderfilters) {
                        _threadRenderfilters = Object.assign([], source.answeringinfo.renderfilters)
                        if (_threadRenderfilters.includes("Labels/Labels/CCDS")) {
                            _threadRenderfilters = _threadRenderfilters.filter(x => x !== "Labels/Labels/CCDS");
                            _threadRenderfilters.push("Labels/Labels/CCDS Current Clean In Use");
                        }
                        if (_threadRenderfilters.includes("Labels/Labels/USPI")) {
                            _threadRenderfilters = _threadRenderfilters.filter(x => x !== "Labels/Labels/USPI");
                            _threadRenderfilters.push("Labels/Labels/USPI Current Clean In Use");
                        }
                        if (_threadRenderfilters.includes("Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)")) {
                            _threadRenderfilters = _threadRenderfilters.filter(x => x !== "Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert/USPI Current clean version (Clean, In-Use)");
                            _threadRenderfilters.push("Labels/Labels");
                        }
                    }
                    _chatConversation.push({
                        IsSucceed: true,
                        Question: source.answeringinfo.question,
                        OutputAnswer: source.answeringinfo.output_answer,
                        Sources: source.answeringinfo.sources,
                        DisplayString: source.answeringinfo.display_string,
                        RecommandationResults: source.answeringinfo.recommandation_results,
                        messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment },
                        questionType: source.answeringinfo.question_type,
                        eventid: source.eventinfo.eventid,
                        isMultiFiles: source.answeringinfo.attachedFiles.length > 0,
                        DisplayStringMultiFiles: source.answeringinfo.display_string_multi_files ? source.answeringinfo.display_string_multi_files : [],
                        SourcesMultiFiles: source.answeringinfo.sources_multi_files_list ? source.answeringinfo.sources_multi_files_list : [],
                        selectedFilters: _threadSelectedFilters,
                        renderfilters: _threadRenderfilters,
                        combinedSummaryMultiFiles: source.answeringinfo.combined_summary_multi_files
                            ? source.answeringinfo.combined_summary_multi_files : source.answeringinfo.combined_summary_multi_files_list
                                ? source.answeringinfo.combined_summary_multi_files_list : "",
                        threadID: _chatHistoryThreadID ? _chatHistoryThreadID : sessionID,
                        Reaction: source.answeringinfo.answer_reaction ? source.answeringinfo.answer_reaction : ReactNone,
                        Feedback: source.answeringinfo.answer_feedback ? source.answeringinfo.answer_feedback : '',
                        isGetAnyAns: source.answeringinfo.isGetAnyAns,
                        isSuccess: source.answeringinfo.isSuccess,
                        lastCreatedDatehour: source.answeringinfo.lastcreateddatehour ? source.answeringinfo.lastcreateddatehour : source.eventinfo.createddatehour,
                        isComprehensive: source.answeringinfo.isComprehensive ? clone(source.answeringinfo.isComprehensive) : false,
                    })
                })

                if (_isMultiFileThread) {
                    let response = await ts.deleteIndexBySessionId(sessionID);
                }
                this.setState({
                    chatConversation: _chatConversation,
                    isProcessing: false,
                    newQuestion: '',
                    chatHistory: chatHistory,
                    isFirstQuestion: false,
                    question: _question,
                    //selectedDocumentType: _selectedDocumentType,
                    selectedFilters: _selectedFilters,
                    renderfilters: _renderfilters,
                    //attachedFiles: _attachedFiles,
                    threadID: _threadID ? _threadID : sessionID,
                    isMultiFileThread: _isMultiFileThread,
                    isComprehensive: _isComprehensive
                }, async () => {
                    this.scrollToBottom();
                    //this.updateAnswerThreaddatehour();
                })
            } else if (this.props.location.state && this.props.location.state.sessionID && this.props.location.state.newQuestion) {
                this.runGenAISearch(this.props.location.state.newQuestion);
            } else {
                this.props.history.push('/genAIAnswering');
            }
        } else {
            this.props.history.push('/genAIAnswering');
        }
    }
    indextrackingData = async (eventid, questionType, question, userQuestion, outputAnswer, sources, displayString, recommandationResults, attachedFiles, sourcesMultiFiles, displayStringMultiFiles, combinedSummaryMultiFiles, lastCreatedDatehour, isComprehensive, isGetAnyAns, isSuccess) => {
        let { sessionID, selectedDocumentType, threadID, selectedFilters, renderfilters } = this.state;
        let answeringinfo = {
            session_id: sessionID,
            thread_id: threadID,
            question_type: questionType,
            question: question,
            userQuestion: userQuestion ? userQuestion : question,
            output_answer: outputAnswer,
            sources: sources,
            display_string: displayString,
            recommandation_results: recommandationResults,
            isGetAnyAns: isGetAnyAns,
            isSuccess: isSuccess,
            selectedDocumentType: [],
            attachedFiles: attachedFiles,
            sources_multi_files_list: sourcesMultiFiles,
            display_string_multi_files: displayStringMultiFiles,
            selectedFilters: selectedFilters,
            renderfilters: renderfilters,
            combined_summary_multi_files_list: combinedSummaryMultiFiles,
            answer_reaction: ReactNone,
            answer_feedback: '',
            lastcreateddatehour: lastCreatedDatehour,
            isComprehensive: isComprehensive
        }
        let temp = await ts.indextrackingData("Conducted Question GenAIAnswering2.0", eventid, answeringinfo);
        this.indexData({ ...answeringinfo, "eventid": eventid });
        setTimeout(async () => {
            if (questionType === "FollowUpQuestion") {
                await this.updateAnswerThreaddatehour();
            } else {
                await this.context.getLatestAnswerThread();
                setTimeout(async () => {
                    await this.context.getLatestAnswerThread();
                    setTimeout(async () => {
                        await this.context.getLatestAnswerThread();
                    }, 1000);
                }, 1000);
            }
        }, 600);

    }
    updateThreadData = async (newEventid, eventid, questionType, question, userQuestion, outputAnswer, sources, displayString, recommandationResults, attachedFiles, sourcesMultiFiles, displayStringMultiFiles, selectedFilters, renderfilters, combinedSummaryMultiFiles, lastCreatedDatehour, isComprehensive, isGetAnyAns, isSuccess) => {
        let { sessionID, selectedDocumentType, threadID } = this.state;
        let answeringinfo = {
            session_id: sessionID,
            thread_id: threadID,
            question_type: questionType,
            question: question,
            userQuestion: userQuestion ? userQuestion : question,
            output_answer: outputAnswer,
            sources: sources,
            display_string: displayString,
            recommandation_results: recommandationResults,
            isGetAnyAns: isGetAnyAns,
            isSuccess: isSuccess,
            selectedDocumentType: [],
            attachedFiles: attachedFiles,
            sources_multi_files_list: sourcesMultiFiles,
            display_string_multi_files: displayStringMultiFiles,
            selectedFilters: selectedFilters,
            renderfilters: renderfilters,
            combined_summary_multi_files_list: combinedSummaryMultiFiles,
            answer_reaction: ReactNone,
            answer_feedback: '',
            lastcreateddatehour: lastCreatedDatehour,
            isComprehensive: isComprehensive,
        }
        let temp = await ts.updateThreadData(eventid, newEventid, answeringinfo);
        this.indexData({ ...answeringinfo, "eventid": newEventid });
        if (questionType === "FollowUpQuestion") {
            setTimeout(async () => {
                await this.updateAnswerThreaddatehour();
            }, 500);
        }
    }
    indexData = (genaianswering2info) => {

        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'),
            localStorage.getItem('wwid'),
            localStorage.getItem('username'),
            localStorage.getItem('function'),
            localStorage.getItem('region'),
            localStorage.getItem('userproducts'),
            localStorage.getItem('doctypepref'),
            localStorage.getItem('sid'),
            localStorage.getItem('deviceType'),
            localStorage.getItem('deviceOS'),
            localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'),
            localStorage.getItem('jnjDepartment'),
            localStorage.getItem('companyname'),
            `Conducted Question Answering2.0`,
            '',
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            {}, {}, genaianswering2info);
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        })
    }

    updateAnswerThreaddatehour = async () => {
        let { sessionID } = this.state;
        let temp = await ts.updateAnswerThreaddatehour(UserData.sub, sessionID);
        setTimeout(async () => {
            await this.context.getLatestAnswerThread();
            setTimeout(async () => {
                await this.context.getLatestAnswerThread();
                setTimeout(async () => {
                    await this.context.getLatestAnswerThread();
                }, 1000);
            }, 1000);
        }, 600);
    }
    handleKeyDown = async (event) => {
        if (['Enter'].includes(event.key) && !event.shiftKey && this.state.newQuestion && this.state.newQuestion.trim()) {
            event.preventDefault();
            event.returnValue = false;
            await this.handleOnClickSend();
        }
    }
    processChatHistories = (index) => {
        let { attachedFiles, chatConversation, threadID, sessionID } = this.state;
        let _chatHistories = {};
        let _chatConversation = Object.assign([], chatConversation);
        _chatConversation = _chatConversation.filter((x, i) => i < index).filter(x => x.threadID === threadID);
        if (chatConversation.length !== 0 && _chatConversation.length === 0 && index !== 0) {
            _chatConversation = Object.assign([], chatConversation);
            _chatConversation = _chatConversation.filter(x => x.threadID === threadID);
        }
        try {
            if (attachedFiles.length > 0) {
                attachedFiles.map((fileItem, fileIndex) => {
                    _chatHistories[fileItem.guid] = [];
                    let responseFilters = _chatConversation.filter(x => x.SourcesMultiFiles.findIndex(y => y.document_guid === fileItem.guid) > -1);
                    responseFilters.map((resItem, resIndex) => {
                        let indexOfSource = resItem.SourcesMultiFiles.findIndex(y => y.document_guid === fileItem.guid)
                        let restDisplayStringMultifilter = resItem.DisplayStringMultiFiles.filter(d => d.source_no.includes(indexOfSource + 1));
                        let finalAns = restDisplayStringMultifilter.map(x => x.display_ans).join(' ')
                        _chatHistories[fileItem.guid].push({ "Question": resItem.Question, "Answer": finalAns })
                    })
                })
            } else {
                _chatHistories[sessionID] = [];
                _chatConversation = _chatConversation.filter(x => x.isMultiFiles === false);
                _chatConversation.map((resItem, resIndex) => {
                    let finalAns = resItem.DisplayString.map(x => x.display_ans).join(' ');
                    _chatHistories[sessionID].push({ "Question": resItem.Question, "Answer": finalAns })
                })
            }
        } catch (error) {
            console.log(error)
        }
        return _chatHistories;
    }
    processSourceSearchIds = (index) => {
        let { chatConversation } = this.state;
        let _sourceSearchIds = [];
        try {
            let _chatConversation = Object.assign([], chatConversation);
            if (index - 1 < _chatConversation.length) {
                _sourceSearchIds = _chatConversation[index - 1].Sources.map(x => x.searchID);
            }
            _sourceSearchIds = uniq(_sourceSearchIds);
        } catch (error) {
            console.log(error)
        }
        return _sourceSearchIds;
    }
    updateRunGenAISearch = (question, indexQuestion, updatedQuestionEventid, updatedSelectedFilters, updatedRenderfilters, _isComprehensive) => {
        let { selectedDocumentType, sessionID, chatHistory, isFirstQuestion, attachedFiles, threadID, isMultiFileThread, renderfilters, selectedFilters } = this.state;
        if (!isMultiFileThread || (isMultiFileThread && attachedFiles.length > 0)) {
            this.setState({ isProcessing: true })
            let _lastCreatedDatehour = getCurrentDate();
            let _chatHistory = [];
            let url = '';
            if (attachedFiles.length > 0) {
                url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_answers`
                if (window.location.origin === 'https://predev-ims.jnj.com') {
                    url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_answers`
                }
            } else {
                url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}ims_answers`
                if (window.location.origin === 'https://predev-ims.jnj.com') {
                    url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}ims_answers`
                }
            }

            _chatHistory = this.processChatHistories(indexQuestion);
            let _sourceSearchId = this.processSourceSearchIds(indexQuestion);
            var config;
            let query = question;
            let filterStr = "";
            let selectedFilter = [];
            var eventid = uuidv4();
            let _attachedFiles = attachedFiles.map(x => { return { name: x.name } })
            let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))

            if (userAccessGroup && userAccessGroup.includes(MISEHCP) && !userAccessGroup.includes(AllContent)) {
                selectedFilter.push(MISEHCPFilter);
            } else {
                selectedFilter = processFilters(updatedRenderfilters);
                if (selectedFilter.length === 0) {
                    // eslint-disable-next-line array-callback-return
                    Object.keys(GenAI2DocTypes).map(key => {
                        selectedFilter.push(GenAI2DocTypes[key]);
                    })
                }
            }
            let _function = localStorage.getItem('function').replace(/"/g, '');
            if (userAccessGroup.includes("AllContent") && userAccessGroup.includes("MISEHCP")) {
                _function = `MISE;${localStorage.getItem('function').replace(/"/g, '')}`;
            }
            else if (userAccessGroup.includes("MISEHCP")) {
                _function = `MISE`;
            }
            else if (userAccessGroup.includes("AllContent")) {
                _function = localStorage.getItem('function').replace(/"/g, '');
            }
            filterStr = this.generateArrayString(selectedFilter);
            config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "query": query.trim(),
                    "filters": filterStr,
                    "function": _function,
                    "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                    "userproducts": localStorage.getItem('userproducts'),
                    "region": localStorage.getItem('region'),
                    "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                    "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                    "source": "docTitle,documentNumber,downloadUrl,docType,searchID,year,fileName",
                    "from": 0,
                    "size": 25,
                    "sortBy": "",
                    "sortField": "",
                    "defaultOperator": "or",
                    "sessionID": sessionID,
                    "threadID": threadID,
                    //"isLabel": selectedDocumentType.includes("Labels") && selectedDocumentType.length === 1,
                    "chat_history": _chatHistory,
                    "IsComprehensive": _isComprehensive,
                    "sourceSearchId": _sourceSearchId,
                }
            }
            let _chatConversation = this.state.chatConversation;
            // _chatConversation.push({ IsSucceed: true, Question: question, OutputAnswer: responseData.output_answer, Sources: responseData.sources, DisplayString: responseData.display_string, RecommandationResults: responseData.recommandation_results, messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment } })
            // this.setState({
            //     chatConversation: _chatConversation,
            //     isProcessing: false,
            //     newQuestion: '',
            //     chatHistory: chatHistory
            // }, () => {
            //     this.scrollToBottom();
            // })
            axios(config)
                .then((resp) => {
                    let response = resp.data;
                    if (attachedFiles.length > 0) {
                        if (response.display_string_multi && response.display_string_multi.length > 0) {
                            if (response.output_answer) {
                                chatHistory[indexQuestion] = { question: question, answer: response.output_answer, threadID: threadID };
                            } else {
                                chatHistory[indexQuestion] = { question: question, answer: "", threadID: threadID };
                            }
                            _chatConversation[indexQuestion] = { IsSucceed: true, Question: question, OutputAnswer: response.output_answer, Sources: [], SourcesMultiFiles: response.sources_multi, DisplayString: [], DisplayStringMultiFiles: response.display_string_multi, RecommandationResults: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, selectedFilters: updatedSelectedFilters, renderfilters: updatedRenderfilters, combinedSummaryMultiFiles: response.combined_summary, threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: true, isSuccess: true };
                            this.updateThreadData(eventid, updatedQuestionEventid, indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", question, response.rephrased_question, response.output_answer, [], [], [], _attachedFiles, response.sources_multi, response.display_string_multi, updatedSelectedFilters, updatedRenderfilters, response.combined_summary, _lastCreatedDatehour, _isComprehensive, true, true);
                        } else {
                            _chatConversation[indexQuestion] = { IsSucceed: false, Question: question, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], DisplayString: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, SourcesMultiFiles: [], DisplayStringMultiFiles: [], selectedFilters: updatedSelectedFilters, renderfilters: updatedRenderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: false, isSuccess: true }
                            this.updateThreadData(eventid, updatedQuestionEventid, indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", question, question, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], updatedSelectedFilters, updatedRenderfilters, '', _lastCreatedDatehour, _isComprehensive, false, true);
                        }

                    } else {
                        if (response.display_string && response.display_string.length > 0) {
                            if (response.output_answer) {
                                chatHistory[indexQuestion] = { question: question, answer: response.output_answer, threadID: threadID };
                            } else {
                                chatHistory[indexQuestion] = { question: question, answer: "", threadID: threadID };
                            }
                            _chatConversation[indexQuestion] = { IsSucceed: true, Question: question, OutputAnswer: response.output_answer, Sources: response.sources, DisplayString: response.display_string, RecommandationResults: response.recommandation_results, SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, selectedFilters: updatedSelectedFilters, renderfilters: updatedRenderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: true, isSuccess: true };
                            this.updateThreadData(eventid, updatedQuestionEventid, indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", question, response.rephrased_question, response.output_answer, response.sources, response.display_string, response.recommandation_results, _attachedFiles, [], [], updatedSelectedFilters, updatedRenderfilters, '', _lastCreatedDatehour, _isComprehensive, true, true);
                        } else {
                            _chatConversation[indexQuestion] = { IsSucceed: false, Question: question, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], DisplayString: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, SourcesMultiFiles: [], DisplayStringMultiFiles: [], selectedFilters: updatedSelectedFilters, renderfilters: updatedRenderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: false, isSuccess: true }
                            this.updateThreadData(eventid, updatedQuestionEventid, indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", question, question, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], updatedSelectedFilters, updatedRenderfilters, '', _lastCreatedDatehour, _isComprehensive, false, true);
                        }
                    }
                })
                .catch((error) => {
                    chatHistory[indexQuestion] = { question: question, answer: "", threadID: threadID };
                    _chatConversation[indexQuestion] = { IsSucceed: false, Question: question, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], DisplayString: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, selectedFilters: updatedSelectedFilters, renderfilters: updatedRenderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: false, isSuccess: false };
                    this.updateThreadData(eventid, updatedQuestionEventid, indexQuestion === 0 ? "MainQuestion" : "FollowUpQuestion", question, question, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], updatedSelectedFilters, updatedRenderfilters, '', _lastCreatedDatehour, _isComprehensive, false, false);
                    console.log("error", error);
                })
                .finally(async () => {
                    this.setState({
                        chatConversation: _chatConversation,
                        isProcessing: false,
                        newQuestion: '',
                        chatHistory: chatHistory,
                        isFirstQuestion: false,
                        renderfilters: (chatHistory.length - 1 === indexQuestion) ? updatedRenderfilters : renderfilters,
                        selectedFilters: (chatHistory.length - 1 === indexQuestion) ? updatedSelectedFilters : selectedFilters,
                        question: indexQuestion === 0 ? question.trim() : this.state.question
                    }, async () => {
                        //this.scrollToBottom();
                    })
                });

        } else {
            this.errorAlertMessage("Kindly re-upload documents.");
        }
    }
    runGenAISearch = (question) => {
        let { selectedDocumentType, sessionID, chatHistory, isFirstQuestion, attachedFiles, threadID, selectedFilters, renderfilters, isMultiFileThread } = this.state;
        let _chatConversation = this.state.chatConversation;
        let _lastCreatedDatehour = getCurrentDate();
        let _isComprehensive = clone(this.state.isComprehensive);
        let url = '';
        let _chatHistory = [];
        if (!isMultiFileThread || (isMultiFileThread && attachedFiles.length > 0)) {
            this.setState({ isProcessing: true })
            if (attachedFiles.length > 0) {
                url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_answers`
                if (window.location.origin === 'https://predev-ims.jnj.com') {
                    url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_answers`
                }
            } else {
                url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}ims_answers`
                if (window.location.origin === 'https://predev-ims.jnj.com') {
                    url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}ims_answers`
                }
            }
            _chatHistory = this.processChatHistories(_chatConversation.length);
            let _sourceSearchId = this.processSourceSearchIds(_chatConversation.length);
            var config;
            let query = question;
            let filterStr = "";
            let selectedFilter = [];
            var eventid = uuidv4();
            let _attachedFiles = attachedFiles.map(x => { return { name: x.name } })
            //let strChatHistory = chatHistory.map(y => `("${y.question.replace(/'/g, "\'")}","${y.answer.replace(/'/g, "\'")}")`).join(",");
            let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
            if (userAccessGroup && userAccessGroup.includes(MISEHCP) && !userAccessGroup.includes(AllContent)) {
                selectedFilter.push(MISEHCPFilter);
            } else {
                selectedFilter = processFilters(renderfilters);
                if (selectedFilter.length === 0) {
                    // eslint-disable-next-line array-callback-return
                    Object.keys(GenAI2DocTypes).map(key => {
                        selectedFilter.push(GenAI2DocTypes[key]);
                    })
                }
            }
            let _function = localStorage.getItem('function').replace(/"/g, '');
            if (userAccessGroup.includes("AllContent") && userAccessGroup.includes("MISEHCP")) {
                _function = `MISE;${localStorage.getItem('function').replace(/"/g, '')}`;
            }
            else if (userAccessGroup.includes("MISEHCP")) {
                _function = `MISE`;
            }
            else if (userAccessGroup.includes("AllContent")) {
                _function = localStorage.getItem('function').replace(/"/g, '');
            }
            filterStr = this.generateArrayString(selectedFilter);
            config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "query": query.trim(),
                    "filters": filterStr,
                    "function": _function,
                    "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                    "userproducts": localStorage.getItem('userproducts'),
                    "region": localStorage.getItem('region'),
                    "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                    "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                    "source": "docTitle,documentNumber,downloadUrl,docType,searchID,year,fileName",
                    "from": 0,
                    "size": 25,
                    "sortBy": "",
                    "sortField": "",
                    "defaultOperator": "or",
                    "sessionID": sessionID,
                    "threadID": threadID,
                    //"isLabel": selectedDocumentType.includes("Labels") && selectedDocumentType.length === 1,
                    "chat_history": _chatHistory,
                    "IsComprehensive": _isComprehensive,
                    "sourceSearchId": _sourceSearchId,
                }
            }
            // _chatConversation.push({ IsSucceed: true, Question: question, OutputAnswer: responseData.output_answer, Sources: responseData.sources, SourcesMultiFiles: [], DisplayString: responseData.display_string, DisplayStringMultiFiles: [], RecommandationResults: responseData.recommandation_results, messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: false, selectedFilters: selectedFilters, renderfilters: renderfilters, lastCreatedDatehour:_lastCreatedDatehour  })
            // _chatConversation.push({ IsSucceed: true, Question: question, OutputAnswer: responseMultiData.output_answer, Sources: [], SourcesMultiFiles: responseMultiData.sources_multi, DisplayString: [], DisplayStringMultiFiles: responseMultiData.display_string_multi, RecommandationResults: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: !isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: true, selectedFilters: selectedFilters, renderfilters: renderfilters, lastCreatedDatehour:_lastCreatedDatehour  })
            // this.setState({
            //     chatConversation: _chatConversation,
            //     isProcessing: false,
            //     newQuestion: '',
            //     chatHistory: chatHistory
            // }, () => {
            //     this.scrollToBottom();
            // })
            axios(config)
                .then((resp) => {
                    let response = resp.data;
                    if (attachedFiles.length > 0) {
                        let isImageUpload = _attachedFiles.some(x =>
                            x.name.toLowerCase().endsWith('.gif')
                            || x.name.toLowerCase().endsWith('.jpeg')
                            || x.name.toLowerCase().endsWith('.jpg')
                            || x.name.toLowerCase().endsWith('.png'))

                        if (response.display_string_multi && response.display_string_multi.length > 0 || isImageUpload) {
                            if (response.output_answer) {
                                chatHistory.push({ question: question, answer: response.output_answer, threadID: threadID });
                            } else {
                                chatHistory.push({ question: question, answer: "", threadID: threadID });
                            }
                            _chatConversation.push({ IsSucceed: true, Question: question, OutputAnswer: response.output_answer, Sources: [], SourcesMultiFiles: response.sources_multi, DisplayString: [], DisplayStringMultiFiles: response.display_string_multi, RecommandationResults: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: response.combined_summary, threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: true, isSuccess: true })
                            this.indextrackingData(eventid, isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", question, response.rephrased_question, response.output_answer, [], [], [], _attachedFiles, response.sources_multi, response.display_string_multi, response.combined_summary, _lastCreatedDatehour, _isComprehensive, true, true);
                        } else {
                            _chatConversation.push({ IsSucceed: false, Question: question, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], SourcesMultiFiles: [], DisplayString: [], DisplayStringMultiFiles: [], RecommandationResults: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFile: attachedFiles.length > 0, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: false, isSuccess: true })
                            chatHistory.push({ question: question, answer: "", threadID: threadID });
                            this.indextrackingData(eventid, isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", question, question, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, _isComprehensive, false, true);
                        }
                    } else {
                        if (response.display_string && response.display_string.length > 0) {
                            if (response.output_answer) {
                                chatHistory.push({ question: question, answer: response.output_answer, threadID: threadID });
                            } else {
                                chatHistory.push({ question: question, answer: "", threadID: threadID });
                            }
                            _chatConversation.push({ IsSucceed: true, Question: question, OutputAnswer: response.output_answer, Sources: response.sources, DisplayString: response.display_string, RecommandationResults: response.recommandation_results, SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: true, isSuccess: true })
                            this.indextrackingData(eventid, isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", question, response.rephrased_question, response.output_answer, response.sources, response.display_string, response.recommandation_results, _attachedFiles, [], [], '', _lastCreatedDatehour, _isComprehensive, true, true);
                        } else {
                            _chatConversation.push({ IsSucceed: false, Question: question, OutputAnswer: "No Answer found. Please try a new question.", Sources: [], DisplayString: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFile: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: false, isSuccess: true })
                            chatHistory.push({ question: question, answer: "", threadID: threadID });
                            this.indextrackingData(eventid, isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", question, question, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, _isComprehensive, false, true);
                        }
                    }
                })
                .catch((error) => {
                    _chatConversation.push({ IsSucceed: false, Question: question, OutputAnswer: "No Answer found. Please try a new question.", SourcesMultiFiles: [], DisplayString: [], DisplayStringMultiFiles: [], RecommandationResults: [], SourcesMultiFiles: [], DisplayStringMultiFiles: [], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, questionType: isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", eventid: eventid, isMultiFiles: attachedFiles.length > 0, selectedFilters: selectedFilters, renderfilters: renderfilters, combinedSummaryMultiFiles: '', threadID: threadID, Reaction: ReactNone, Feedback: '', lastCreatedDatehour: _lastCreatedDatehour, isComprehensive: _isComprehensive, isGetAnyAns: false, isSuccess: false })
                    chatHistory.push({ question: question, answer: "", threadID: threadID });
                    this.indextrackingData(eventid, isFirstQuestion ? "MainQuestion" : "FollowUpQuestion", question, question, "No Answer found. Please try a new question.", [], [], [], _attachedFiles, [], [], '', _lastCreatedDatehour, _isComprehensive, false, false);
                    console.log("error", error);
                })
                .finally(async () => {
                    this.setState({
                        chatConversation: _chatConversation,
                        isProcessing: false,
                        newQuestion: '',
                        chatHistory: chatHistory,
                        isFirstQuestion: false,
                        renderfilters: renderfilters,
                        selectedFilters: selectedFilters
                    }, async () => {
                        this.scrollToBottom();
                    })
                });
        }
        else {
            this.errorAlertMessage("Kindly re-upload documents.");
        }
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    multipleFilesUpload = async (e) => {
        this.setState({ isProcessing: true, spinnerMessage: "Please wait while uploading the document.", focusDropDownCollapsed: false, attachedFilePopupCollapsed: false });
        let { attachedFiles, sessionID, updateAnyFile, newAttachedFiles, removedAttachedFileIndex, newThreadID } = this.state;
        var url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_upload`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ANSWERS}multi_upload`
        }
        const formData = new FormData();
        attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).map((item, index) => {
            formData.append(`file${index + 1}`, item);
            formData.append(`fileGuid${index + 1}`, item.guid);
        })
        formData.append(`fileCount`, attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).length);
        formData.append(`sessionID`, sessionID);
        formData.append(`threadID`, newThreadID);
        formData.append(`username`, localStorage.getItem('username').toLowerCase().replace(/"/g, ''));
        formData.append(`updateAnyFile`, updateAnyFile);

        let config = {
            method: 'POST',
            url: url,
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        }
        await axios(config).then((resp) => {
            let response = resp.data;
            if (response.FileUpload) {
                let isRemoveFile = this.state.removedAttachedFileIndex !== -1;
                let isSingleFile = newAttachedFiles.length === 1;
                this.setState({ updateAnyFile: true, attachedFiles: attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex), newAttachedFiles: [], removedAttachedFileIndex: -1, threadID: newThreadID, selectedFilters: [], renderfilters: [], isComprehensive: false, }, async () => {
                    this.manageFilePlaceHolder();
                    if (isRemoveFile) {
                        this.successAlertMessage("Document removed successfully.");
                    } else {
                        this.successAlertMessage(isSingleFile ? `Document uploaded successfully.` : `Documents uploaded successfully.`);
                    }
                    await this.context.updateSessionId(sessionID);
                });
            } else if (!response.FileUpload && response.Files && response.Files.length === 0) {
                this.errorAlertMessage(`Upload failed due to some reason. Kindly try after some time.<br /><br /><p class='alertmessage-file-label'>Documents name</p> - ${newAttachedFiles.map(x => x.name).join("<br /> - ")}`)
                this.setState({ updateAnyFile: true, newAttachedFiles: [], removedAttachedFileIndex: -1, selectedFilters: [], renderfilters: [], isComprehensive: false, }, async () => {
                    this.manageFilePlaceHolder();
                    await this.context.updateSessionId(sessionID);
                });
            }
            else if (!response.FileUpload && response.Files && response.Files.length > 0) {
                let failedGuid = response.Files.map(x => x.Guid)
                let finalFaileAttached = attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).filter(x => failedGuid.includes(x.guid));
                this.errorAlertMessage(`Upload failed due to some reason. Kindly try after some time.<br /><br /><p class='alertmessage-file-label'>Documents name</p> - ${finalFaileAttached.map(x => x.name).join("<br /> - ")}`)
                this.setState({
                    updateAnyFile: true, attachedFiles: attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).filter(x => !failedGuid.includes(x.guid)),
                    newAttachedFiles: [], removedAttachedFileIndex: -1, selectedFilters: [], renderfilters: [], isComprehensive: false,
                }, async () => {
                    this.manageFilePlaceHolder();
                    await this.context.updateSessionId(sessionID);
                });
            }
            // this.setState({ updateAnyFile: true, attachedFiles: attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex), newAttachedFiles: [], removedAttachedFileIndex: -1, threadID: newThreadID, chatHistory: [] }, async () => {
            //     this.manageFilePlaceHolder();
            //     await this.context.updateSessionId(sessionID);
            // });

        }).catch((error) => {
            let isRemoveFile = this.state.removedAttachedFileIndex !== -1;
            this.setState({ newAttachedFiles: [], removedAttachedFileIndex: -1 }, () => {
                if (isRemoveFile) {
                    this.errorAlertMessage(`Remove document failed to some reason. Kindly try after some time.`)
                } else {
                    this.errorAlertMessage(`Upload failed due to some reason. Kindly try after some time.<br /><br /><p class='alertmessage-file-label'>Documents name</p> - ${newAttachedFiles.map(x => x.name).join("<br /> - ")}`)
                }
            });

            console.log(error)
        }).finally(() => {
            this.setState({ isProcessing: false, spinnerMessage: '' });
        });
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }

    async fileInputChange(event) {
        const files = event.target.files;
        let { attachedFiles } = this.state;
        if ((attachedFiles.length + Object.keys(files).length) > 5) {
            this.errorAlertMessage("You can only upload a maximum of 5 files");
        } else {
            let _attachedFiles = [];
            let isUploadSuccessfully = true;
            for (var i = 0; i < Object.keys(files).length; i++) {
                let file = files[i];
                if (file.name.toLowerCase().endsWith('.pdf')
                    || file.name.toLowerCase().endsWith('.jpg')
                    || file.name.toLowerCase().endsWith('.jpeg')
                    || file.name.toLowerCase().endsWith('.png')
                    || file.name.toLowerCase().endsWith('.gif')) {
                    file.guid = uuidv4();
                    file.newFileName = file.name;
                    _attachedFiles.push(file);
                } else {
                    this.setState({ isProcessing: true, attachedFilePopupCollapsed: false });
                    let API_ENDPOINT = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/PDFMaker`;
                    if (file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx')) {
                        API_ENDPOINT += "?fileType=Document"
                    } else {
                        API_ENDPOINT += "?fileType=Presentation"
                    }
                    const formData = new FormData();
                    formData.append('form_filed_name', file)
                    await axios.post(API_ENDPOINT, formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        responseType: "blob",
                    }).then((res) => {
                        let newFileName = file.name.split('.').slice(0, -1).join('.');
                        const fileName = `${newFileName}.pdf`;
                        const data = new Blob([res.data], { type: 'application/pdf' });
                        var newFile = new File([data], file.name, { type: 'application/pdf' });
                        newFile.guid = uuidv4();
                        newFile.newFileName = fileName;
                        _attachedFiles.push(newFile);
                    }).catch((error) => {
                        console.log(error);
                        isUploadSuccessfully = false;
                    });
                }
                if (!isUploadSuccessfully) {
                    break;
                }
            }
            if (!isUploadSuccessfully) {
                this.setState({ newAttachedFiles: [], removedAttachedFileIndex: -1, isProcessing: false }, () => {
                    this.errorAlertMessage("Upload failed due to some reason. Kindly try after some time.");
                });
            } else {
                var threadGuid = uuidv4();
                this.setState({ newAttachedFiles: _attachedFiles, newThreadID: threadGuid }, async () => {
                    await this.multipleFilesUpload();
                });
            }
        }
    }
    manageFilePlaceHolder() {
        let { attachedFiles } = this.state;
        let uploadFilePlaceHolder = '', uploadFilePlaceHolderTitle = '';
        if (attachedFiles.length > 0) {
            let singleFile = attachedFiles[0];
            uploadFilePlaceHolder = this.getAttachedFileName(singleFile);
            uploadFilePlaceHolderTitle = singleFile.name;
        }
        this.setState({ uploadFilePlaceHolder: uploadFilePlaceHolder, uploadFilePlaceHolderTitle: uploadFilePlaceHolderTitle, isAttachedFile: attachedFiles.length > 0 });
    }
    handleRemoveAllAttachedFile = () => {
        this.setState({
            uploadFilePlaceHolder: "",
            uploadFilePlaceHolderTitle: "",
            attachedFiles: [],
            newAttachedFiles: [],
            isAttachedFile: false,
            focusDropDownCollapsed: false,
            attachedFilePopupCollapsed: false,
            newAttachedFiles: [],
            removedAttachedFileIndex: -1,
            updateAnyFile: false,
        }, async () => {
            let { sessionID } = this.state;
            let response = await ts.deleteIndexBySessionId(sessionID);
            await this.context.updateSessionId("");
            this.successAlertMessage("Documents removed successfully.");
        })
    }
    handleRemoveAttachedFile = (index) => {
        let { attachedFiles } = this.state;
        attachedFiles = attachedFiles.filter((file, fileIndex) => fileIndex !== index);
        if (attachedFiles.length > 0) {
            var threadGuid = uuidv4();
            this.setState({ removedAttachedFileIndex: index, newThreadID: threadGuid }, async () => {
                await this.multipleFilesUpload();
            });
        } else {
            this.setState({
                uploadFilePlaceHolder: "",
                uploadFilePlaceHolderTitle: "",
                attachedFiles: [],
                isAttachedFile: false,
                focusDropDownCollapsed: false,
                attachedFilePopupCollapsed: false,
                updateAnyFile: false,
            }, async () => {
                let { sessionID } = this.state;
                let response = await ts.deleteIndexBySessionId(sessionID);
                await this.context.updateSessionId("");
                this.successAlertMessage("Document removed successfully.");
            })
        }
    }
    handleNewQuestion = (e) => {
        this.setState({ newQuestion: e.target.value })
    }
    handleOnClickSend = () => {
        let { newQuestion } = this.state;
        this.runGenAISearch(newQuestion);

    }
    handleOnClickAttachedFile = () => {
        const { attachedFilePopupCollapsed } = this.state;
        this.setState({ attachedFilePopupCollapsed: !attachedFilePopupCollapsed })
    }
    getAttachedFileName(singleFile) {
        let filename = singleFile.name.split('.').slice(0, -1).join('.');
        let fileExtention = singleFile.name.substring(singleFile.name.lastIndexOf('.') + 1, singleFile.name.length) || singleFile.name;
        filename = this.snippetLongStringForKeyword(filename);
        return `${filename} .${fileExtention}`;;
    }

    snippetLongStringForKeyword = (snippet, strLength = 25) => {
        if (snippet.length > strLength) {
            let text = snippet.substring(0, strLength)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    handleLeftMouseClick = (e, _id) => {
        const x = e.clientX;
        const y = e.clientY;
        showMenu({
            position: { x, y },
            id: _id
        });
    }
    handleSelectedFilters = (selectedFilters) => {
        this.setState({
            selectedFilters: selectedFilters
        })
    }
    handleRenderfilters = (renderfilters) => {
        this.setState({
            renderfilters: renderfilters
        })
    }
    handleDisclaimerClose = async () => {
        this.setState({ showDisclaimerPopup: false });
    }
    renderFileIcon = (name) => {
        if (name.toLowerCase().endsWith('.pdf')) {
            return (<><img src={GenAiPDFIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.doc') || name.toLowerCase().endsWith('.docx')) {
            return (<><img src={GenAiDOCIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.ppt') || name.toLowerCase().endsWith('.pptx')) {
            return (<><img src={GenAiPPTIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.jpg')
            || name.toLowerCase().endsWith('.jpeg')
            || name.toLowerCase().endsWith('.png')
            || name.toLowerCase().endsWith('.gif')) {
            return (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5" stroke-width="0" fill="#059cf7" opacity="0.1"></polygon>
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5"></polygon>
                    <rect x="2" y="2" width="20" height="20" rx="0"></rect>
                    <line x1="6.99" y1="7" x2="7" y2="7" stroke-linecap="round" stroke-width="2"></line>
                </g>
            </svg> &nbsp;</>)
        }
        else {
            return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>)
        }
    }
    renderAttachedFile = () => {
        let { attachedFilePopupCollapsed, attachedFiles, isAttachedFile } = this.state;

        return (<div className={attachedFilePopupCollapsed ? "gen-ai-answering-search-main-attachedfile-wrapper gen-ai-answering-main-attachedfile-open" : "gen-ai-answering-search-main-attachedfile-wrapper gen-ai-answering-main-attachedfile-close"}>
            <div className="gen-ai-answering-main-attachedfile-header">
                <div className="gen-ai-answering-main-attachedfile-header-title">
                    Attached Files
                </div>
                <div className="gen-ai-answering-main-attachedfile-header-controle">
                    {attachedFiles.length < 5 && <>
                        <label for="answeringAttachedfile" style={{ cursor: "pointer" }} className="gen-ai-answering-main-attachedfile-header-controle-add">
                            <div className="flex items-center leading-none justify-center gap-xs">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path>
                                </svg>
                                <div class="text-align-center relative">Add</div>
                            </div>
                        </label>
                        <input type="file" key={isAttachedFile} name="answeringAttachedfile" id="answeringAttachedfile" multiple accept={multiFilesAccept} onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                        &nbsp;</>}

                    <button type="button" class="gen-ai-answering-main-attachedfile-header-controle-delete" onClick={e => this.handleRemoveAllAttachedFile()}>
                        <div class="flex items-center leading-none justify-center gap-xs">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path>
                            </svg>
                            <div class="text-align-center relative">Clear</div>
                        </div>
                    </button>
                </div>
            </div>
            <div className="gen-ai-answering-main-attachedfile-items">
                <div className="gen-ai-answering-main-attachedfile-item-wrapper">
                    {
                        attachedFiles.map((fileItem, fileIndex) => {
                            return (<div className="gen-ai-answering-main-attachedfile-item" key={`gen-ai-answering-main-attachedfile-item${fileIndex}`} title={fileItem.name}>
                                <span className="gen-ai-answering-main-attachedfile-item-span">
                                    {this.renderFileIcon(fileItem.name)}
                                    {this.getAttachedFileName(fileItem)}
                                </span>
                                <button style={{ background: "none" }} title="Clear Attached" onClick={e => this.handleRemoveAttachedFile(fileIndex)}>
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" class="svg-inline--fa fa-xmark fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path fill="currentColor" d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"></path>
                                    </svg>
                                </button>
                            </div>)
                        })
                    }
                </div>
            </div>
        </div>)
    }
    handleOnClickReaction = async (index, reaction) => {
        let { chatConversation, } = this.state;
        chatConversation[index].Reaction = reaction;
        await this.setState({
            chatConversation: chatConversation
        })
    }
    handleOnClickFeedback = async (index, feedback) => {
        let { chatConversation, } = this.state;
        chatConversation[index].Feedback = feedback;
        await this.setState({
            chatConversation: chatConversation
        })
    }
    renderChatConversation = () => {
        let { chatConversation, customWidth, customHeight, attachedFiles, sessionID } = this.state;
        return (<>
            {chatConversation.map((conversation, conversationIndex) => {
                let { Question, OutputAnswer, Sources, DisplayString,
                    RecommandationResults, SourcesMultiFiles,
                    DisplayStringMultiFiles, isMultiFiles, selectedFilters,
                    renderfilters, combinedSummaryMultiFiles, Reaction, Feedback,
                    isGetAnyAns, isSuccess, lastCreatedDatehour, isComprehensive } = conversation;
                combinedSummaryMultiFiles = (Array.isArray(combinedSummaryMultiFiles) && combinedSummaryMultiFiles && combinedSummaryMultiFiles.length > 0) ? combinedSummaryMultiFiles.filter(x => x !== 'No answer found in the document') : combinedSummaryMultiFiles;
                return (<React.Fragment key={`GenAIResultIndex-${conversation.eventid}-${conversationIndex}`}>
                    <GenAIResultIndex eventid={conversation.eventid} customWidth={customWidth} customHeight={customHeight} Question={Question} OutputAnswer={OutputAnswer} Sources={Sources} DisplayString={DisplayString}
                        RecommandationResults={RecommandationResults} conversationIndex={conversationIndex} updateRunGenAISearch={this.updateRunGenAISearch}
                        SourcesMultiFiles={SourcesMultiFiles} DisplayStringMultiFiles={DisplayStringMultiFiles} isMultiFiles={isMultiFiles} attachedFiles={attachedFiles}
                        selectedFilters={selectedFilters} renderfilters={renderfilters} CombinedSummaryMultiFiles={combinedSummaryMultiFiles}
                        reaction={Reaction} feedback={Feedback} handleOnClickReaction={this.handleOnClickReaction}
                        sessionID={sessionID} questionType={conversation.questionType} threadID={conversation.threadID}
                        lastCreatedDatehour={lastCreatedDatehour}
                        isGetAnyAns={isGetAnyAns} isSuccess={isSuccess} handleOnClickFeedback={this.handleOnClickFeedback}
                        isComprehensive={isComprehensive} />
                    {(conversationIndex < chatConversation.length - 1) && <hr style={{ margin: "0 20px" }}></hr>}
                </React.Fragment>)
            })}
            <div style={{ float: "left", clear: "both", height: "185px" }}
                ref={(el) => { this.messagesEnd = el; }}>
            </div>
        </>)
    }
    handleScroll = () => {
        let currScroll = 0;
        if (this.myDiv && this.myDiv.scrollTop) {
            currScroll = this.myDiv.scrollTop;
        }
        this.setState({ scrollingUp: currScroll > 30 })
    }
    _onIdle(e) {
        this.setState({ isTimeOut: true });
    }
    handleClickOnContinue = async () => {
        this.setState({ isTimeOut: false });
        this.idleTimer.reset();
    }
    closeAttachedFilePopupCollapsed = () => {
        this.setState({ attachedFilePopupCollapsed: false })
    }
    handleDocumentFiltersClick = () => {
        this.setState({ isDTPopupOpen: true });
    }
    handleCloseDocumentFiltersClick = () => {
        this.setState({ isDTPopupOpen: false });
    }
    handleOnChangeToggleSwitch = (checked) => {
        this.setState({
            isComprehensive: checked
        })
    }
    render() {

        let { newQuestion, attachedFiles, scrollingUp, question, isCollectionProcessing, threadCollections, spinnerMessage, isMultiFileThread, isComprehensive } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, uploadFilePlaceHolderTitle, uploadFilePlaceHolder, selectedDocumentType, selectedFilters, renderfilters } = this.state;
        let { showDisclaimerPopup } = this.state;
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<><div className="gen-ai-answering-search-main" ref={(el) => { this.myDiv = el; }} id="gen-ai-answering-search-main">
                <header className={`${scrollingUp ? this.context.isCollapsedSpin ? "stickyHeaderCollapse" : "stickyHeaderExpand" : ""}`}>
                    <div className="gen-ai-answering-search-main-header-collection">
                        <div style={{ width: "54px" }}></div>
                        <div className="gen-ai-answering-search-main-header-collection-question">
                            <div className="gen-ai-answering-search-main-header-collection-title">
                                {isCollectionProcessing ? <>
                                    <img src={imsLoader} alt="" width={30} height={30} />
                                </> : <>
                                    {threadCollections.length > 0 ? <>  <ContextMenuTrigger id={this.state.sessionID} >
                                        <div className="gen-ai-answering-search-main-header-collection-svg" onClick={e => this.handleLeftMouseClick(e, this.state.sessionID)}>
                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="grid-round-2" className="svg-inline--fa fa-grid-round-2 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M128 176a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 128a96 96 0 1 1 192 0zM128 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 384a96 96 0 1 1 192 0zM336 128a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zm0 208a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48a96 96 0 1 1 -192 0 96 96 0 1 1 192 0z">
                                                </path>
                                            </svg>
                                            &nbsp;
                                            <div>{this.snippetLongStringForKeyword(threadCollections[0].collection_name, 30)}</div>
                                            &nbsp;
                                        </div>
                                    </ContextMenuTrigger>
                                        <ContextMenu id={this.state.sessionID} className="gen-ai-answering-library-thread-collections-item-contextMenu">
                                            <div className="gen-ai-answering-library-thread-item-contextMenu-collections-title">
                                                Collections
                                            </div>
                                            <div className="gen-ai-answering-library-thread-item-contextMenu-collections-item">
                                                {threadCollections.map((tcItem, tcIndex) => {
                                                    return (<div className="gen-ai-answering-library-thread-item-contextMenu-collections-item-title" key={`gen-ai-answering-library-thread-item-contextMenu-collections-item${tcItem.collection_id}`}>
                                                        <Link to={`/genAICollection/${tcItem.collection_id}`} title={`${tcIndex + 1}. ${tcItem.collection_name}`} className="gen-ai-answering-library-thread-collection-item-title-link" >
                                                            {tcIndex + 1}. {tcItem.collection_name}
                                                        </Link>
                                                    </div>)
                                                })}
                                            </div>
                                        </ContextMenu></> : <div className="gen-ai-answering-search-main-header-collection-svg" onClick={this.handleAddtoCollectionClick}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path>
                                        </svg>
                                        &nbsp;
                                        <div>Add to Collection</div>
                                        &nbsp;
                                    </div>}
                                </>}
                            </div>
                            <div className="gen-ai-answering-search-main-header-collection-slash-forward">
                                &nbsp;
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="slash-forward" class="svg-inline--fa fa-slash-forward " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor" d="M308.1 3.3c11.4 6.7 15.3 21.4 8.6 32.8l-272 464c-6.7 11.4-21.4 15.3-32.8 8.6S-3.4 487.3 3.3 475.9l272-464C282 .4 296.7-3.4 308.1 3.3z"></path>
                                </svg>
                                &nbsp;
                            </div>
                            <div className="gen-ai-answering-search-main-header-collection-thread">
                                &nbsp;
                                {question && <span className="line-clamp-1">{this.snippetLongStringForKeyword(question, 30)}</span>}
                            </div>
                        </div>
                        <GenAIClose />
                    </div>
                </header>
                {(attachedFiles && attachedFiles.length > 0) &&
                    <IdleTimer
                        key="GenAISearchIndex"
                        ref={ref => { this.idleTimer = ref }}
                        onIdle={this.onIdle}
                        debounce={250}
                        timeout={this.state.timeout}
                        crossTab={{
                            emitOnAllTabs: true
                        }} />}
                {this.renderChatConversation()}
                {this.renderAttachedFile()}
                <div className="gen-ai-answering-search-textarea-main">
                    <div className="gen-ai-answering-main-search-question">
                        <div className="gen-ai-answering-main-search-question-txt-btn-section">
                            <textarea placeholder="Ask follow-up" value={newQuestion} onKeyDown={this.handleKeyDown} onChange={(e) => this.handleNewQuestion(e)} onClick={(e) => { this.closeAttachedFilePopupCollapsed() }} autocomplete="off" />
                            <button type="button" disabled={!newQuestion} className="gen-ai-answering-main-search-action-section-send" onClick={(e) =>
                                this.handleOnClickSend()}>
                                <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="-11.19 -11.19 52.62 52.62" stroke="#ffffff">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0">
                                        <rect x="-11.19" y="-11.19" width="52.62" height="52.62" rx="26.31" fill={!newQuestion ? "#cecece" : "#2a498a"} strokewidth="0"></rect>
                                    </g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g>
                                            <path d="M20.194,3.46c-4.613-4.613-12.121-4.613-16.734,0c-4.612,4.614-4.612,12.121,0,16.735 c4.108,4.107,10.506,4.547,15.116,1.34c0.097,0.459,0.319,0.897,0.676,1.254l6.718,6.718c0.979,0.977,2.561,0.977,3.535,0 c0.978-0.978,0.978-2.56,0-3.535l-6.718-6.72c-0.355-0.354-0.794-0.577-1.253-0.674C24.743,13.967,24.303,7.57,20.194,3.46z M18.073,18.074c-3.444,3.444-9.049,3.444-12.492,0c-3.442-3.444-3.442-9.048,0-12.492c3.443-3.443,9.048-3.443,12.492,0 C21.517,9.026,21.517,14.63,18.073,18.074z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="gen-ai-answering-main-search-action-section">
                            {(userAccessGroup.includes(MISEHCP) && !userAccessGroup.includes(AllContent)) ? <span>&nbsp;</span> : isMultiFileThread ? <>&nbsp;</> : <button type="button" onClick={(e) =>
                                this.handleDocumentFiltersClick()} className="gen-ai-answering-main-search-action-section-document-filters" >Document Filters</button>}
                            {isMultiFileThread ? <><div className="gen-ai-answering-search-textarea-main-controle-file">
                                {attachedFiles.length === 0 ? <>
                                    <label for="pdfUploadFile" className="gen-ai-answering-main-action-section-file" style={{ cursor: "pointer", marginTop: "5px" }}>
                                        <svg width="30px" height="30px" viewBox="0 0 64.00 64.00" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#ffffff" stroke="#ffffff" stroke-width="0.00064">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                                <rect x="0" y="0" width="64.00" height="64.00" rx="32" fill="#2a498a" strokewidth="0"></rect>
                                            </g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.384"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm5.143 28.305V49H26.857V30.305H16L32 15l16 15.305H37.143z" fill="#ffffff"></path>
                                            </g>
                                        </svg> <b>Upload Documents</b>
                                    </label>
                                </> : <>
                                    <div className="gen-ai-answering-main-search-action-section-listfile" onClick={() => this.handleOnClickAttachedFile()}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>
                                        <label className="gen-ai-answering-main-action-section-upload" title={uploadFilePlaceHolderTitle} style={{ cursor: "pointer" }} >
                                            {uploadFilePlaceHolder}</label>
                                    </div>
                                </>}

                                <input type="file" name="pdfUploadFile" id="pdfUploadFile" multiple accept={multiFilesAccept} onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                            </div>
                            </>
                                : <>
                                    {/* <div className="gen-ai-answering-main-action-section-comprehensive-search"><b>Comprehensive Answer</b>&nbsp;<ToggleSwitch key="gen-ai-search-toggle" customKey="gen-ai-search-toggle" isComprehensive={isComprehensive} handleOnChangeToggleSwitch={this.handleOnChangeToggleSwitch} /></div> */}
                                </>}

                        </div>
                    </div>
                    <div className="gen-ai-answering-follow-up-footer-content">
                        <p>GenAI Answers may display inaccurate information, be sure to verify its responses. <a className="authoring-model-body-content-link" onClick={() => { this.setState({ showDisclaimerPopup: true }) }} >GenAI Adherence Policy</a></p>
                    </div>

                </div>
            </div>
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="gen-ai-answering-search-spinner-popup"
                        backdropClassName="z-index-gen-ai-answering-search-spinner-popup"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait while finding answers.`} </div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
                {this.state.collectionPopup && (
                    <CollectionpopUp
                        showModal={this.state.collectionPopup}
                        handleClose={this.handleCloseCollection}
                        customWidth={this.state.customWidth}
                        sessionId={this.state.sessionID}
                        threadCollections={[]}
                    />
                )}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                {this.state.isTimeOut &&
                    <Modal
                        size="md sm xs"
                        show={this.state.isTimeOut}
                        keyboard={false}
                        dialogClassName="popTimeOut"
                        className="z-index-popTimeOut"
                        backdropClassName="z-index-popTimeOut-backdrop">
                        <div style={{ borderLeft: `5px solid #2EB000` }}>
                            <Modal.Body>
                                Press Continue button, otherwise session will end.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button text="Continue" customColor='#2EB000' action={
                                    this.handleClickOnContinue
                                } />
                            </Modal.Footer>
                        </div>
                    </Modal>
                }
                {this.state.isDTPopupOpen &&
                    <Modal
                        show={this.state.isDTPopupOpen}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="gen-ai-answering-search-spinner-popup"
                        backdropClassName="z-index-gen-ai-answering-search-spinner-popup"
                        scrollable={true}>

                        <Modal.Body className="gen-ai-answering-main-search-action-document-filters-popup-model-body">
                            <div onClick={e => this.handleCloseDocumentFiltersClick()} className="authoring-model-body-disclaimer-cross" >
                                <img src={crossIcon} width={12} height={12} alt="Cross" />
                            </div>
                            <div className="gen-ai-answering-main-search-action-document-filters-body">
                                <GenAIDocumentTypesFilters
                                    handleSelectedFilters={this.handleSelectedFilters}
                                    handleRenderfilters={this.handleRenderfilters}
                                    selectedFilters={selectedFilters}
                                    renderfilters={renderfilters}
                                    customHeight={this.state.customHeight}
                                    customWidth={this.state.customWidth}
                                    isOpenInPopup={true} />
                            </div>
                        </Modal.Body>
                    </Modal>
                }
                {showDisclaimerPopup && <GenAIAnsweringDisclaimer showPopup={showDisclaimerPopup} handleDisclaimerClose={this.handleDisclaimerClose} />}
            </>)
        }
    }

}
export default withRouter(GenAISearchIndex);